import React from "react";
import styled from "styled-components";
import { ContentWrapper, GreyBg, H2Css } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";
import { urls, extendUrlWithSourceVersion } from "../constants/urls";

const HomeInfoBoxes = ({ hasGreyBg, hasMarginTop }) => {
    const Content = (
        <Wrapper>
            <StyledContentWrapper hasMarginTop={hasMarginTop}>
                <Title center>miracl. Die smarteste Art der Immobilienfinanzierung.</Title>

                <BoxesWrapper>
                    <Box className="bgStyle1">
                        <h3 className="h3">
                            Online
                            <br />
                            Kreditvergleich
                        </h3>
                        <p>Vergleiche sofort die aktuellen Konditionen deiner Hausbank mit vielen anderen Banken.</p>
                        <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                            Jetzt vergleichen
                        </a>
                    </Box>
                    <Box className="bgStyle2">
                        <h3 className="h3">
                            Persönliche
                            <br />
                            Betreuung
                        </h3>
                        <p>Profitiere von unserer kostenlosen Beratung und Abwicklung mit deiner Wunschbank.</p>
                        <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                            Lass dich beraten
                        </a>
                    </Box>
                    <Box className="bgStyle1">
                        <h3 className="h3">
                            Leistbarkeit
                            <br />
                            berechnen
                        </h3>
                        <p>Überprüfe online und kostenlos wieviel Kredit du dir leisten kannst.</p>
                        <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                            Jetzt berechnen
                        </a>
                    </Box>
                </BoxesWrapper>
            </StyledContentWrapper>
        </Wrapper>
    );
    if (hasGreyBg) return <GreyBg>{Content}</GreyBg>;
    return Content;
};

const Wrapper = styled.div`
    .h3 {
        color: #fff;
    }
    .bgStyle1 {
        background: transparent linear-gradient(16deg, #4a4aff 0%, #46c6fb 100%) 0% 0% no-repeat padding-box;
    }
    .bgStyle2 {
        background: transparent linear-gradient(50deg, #27e4d1 0%, #4a4aff 100%) 0% 0% no-repeat padding-box;
    }
    .bgStyle3 {
        background: transparent linear-gradient(120deg, #46c6fb 0%, #4a4aff 100%) 0% 0% no-repeat padding-box;
    }
`;

const StyledContentWrapper = styled(ContentWrapper)`
    @media (max-width: ${breakpoints.tabletMax}) {
        padding-left: 0;
        padding-right: 0;
    }
`;

const Title = styled.h2`
    ${H2Css};

    @media (max-width: ${breakpoints.tabletMax}) {
        display: none;
    }
`;

const BoxesWrapper = styled.div`
    margin: 0 auto;
    position: relative;

    max-width: 594px;

    @media (min-width: 1000px) {
        display: flex;
        justify-content: space-between;
        max-width: 900px;
    }

    @media (min-width: 1200px) {
        max-width: 1180px;
    }
`;

const Box = styled.div`
    border-radius: 32px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #00000029;
    color: #fff;
    font-size: 14px;
    position: relative;
    display: flex;
    flex-direction: column;

    p {
        margin: 0 0 30px 0;
    }

    .btn {
        background: none;
        color: #fff;
        display: block;
        border: 1px solid #fff;
        margin: auto auto 10px auto;
        min-width: 200px;
        white-space: nowrap;
    }

    @media (max-width: ${breakpoints.mobileBigMax}) {
        padding: 34px 20px 20px;
        margin: 30px auto 0;
        max-width: 310px;
        width: 100%;
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        min-height: 290px;
        padding: 34px 20px 20px;
        width: 280px;
    }

    @media (min-width: ${breakpoints.tabletBigMin}) and (max-width: 999px) {
        &:first-child {
            margin-bottom: 36px;
        }
        &:nth-child(2) {
            position: absolute;
            right: 0;
            top: 130px;
        }
    }

    @media (min-width: ${breakpoints.tabletBigMin}) and (max-width: 1199px) {
        h3 {
            font-size: 25px;
        }
    }

    @media (min-width: 1200px) {
        min-height: 310px;
        padding: 50px 30px 30px;
        width: 345px;
    }
`;

export default HomeInfoBoxes;
